import { graphql } from "gatsby";
import React, { useState } from "react";
import Image from "gatsby-plugin-sanity-image";
import styled from "styled-components";
import Nav from "../components/Nav";
import SEO from "../components/SEO";
const Wrapper = styled.div`
  max-width: 900px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(100, 1fr);
`;
const TitleCard = styled.h1`
  grid-area: 1 / 1 / 2 / 40;
  color: var(--grey);
  font-style: italic;
  font-weight: bold;
  padding: 10px;
  background-color: #7a0301;
  margin: 30px 20px;
  z-index: 1;
  box-shadow: -10px 26px 8px rgba(0, 0, 0, 0.25);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    grid-area: 1 / 15 / 2 / 90;
    margin: 60px 20px;
  }
`;
const NavStyle = styled.div`
  grid-column: 1 / 29;
  grid-row: 2;
  z-index: 1;
  @media (max-width: 500px) {
    grid-column: 1/45;
  }
`;
const TopImage = styled(Image)`
  grid-column: 40 / 98;
  grid-row: 1;
  margin-top: 40px;
  @media (max-width: 500px) {
    display: none;
  }
`;
const BottomImage = styled.div`
  grid-column: 1 / 32;
  margin-top: 280px;
  margin-left: 10px;
  grid-row: 2;
  z-index: -1;
  @media (max-width: 500px) {
    grid-row: 2;
    margin-top: 0px;
    margin-left: 0;
    grid-column: 20 / 90;
  }
`;
const FormCard = styled.div`
  grid-row: 2 /4;
  grid-column: 38 / 100;
  max-height: 550px;
  display: grid;
  grid-template-columns: 1fr;

  margin: 30px 0;
  color: var(--grey);
  z-index: 1;
  background-color: #999999;
  box-shadow: -15px 23px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 500px) {
    grid-row: 3/5;
    grid-column: 10 / 95;
  }
`;
const FormGrid = styled.div`
  width: 95%;
  margin: auto;
`;
const FormTitle = styled.h3`
  color: #1d1d1d;
  text-align: center;
  margin: auto;
`;
const FormButton = styled.button`
  margin: 10px;
  color: #333333;
  background-color: #7c7c7c;
  &:hover {
    background-color: #535353;
    color: whitesmoke;
  }
`;

export default function Contact({ data }) {
  console.log(data)
  const [values, setValue] = useState({
    name: "",
    email: "",
    subject: "",
    mapleSyrup: "",
    message: "",
  });
  const [formSent, setFormSent] = useState(false);
  const [errors, setErrors] = useState([]);

  function updateValue(e) {
    // check if its a number and convert
    let { value } = e.target;
    if (e.target.type === "number") {
      value = parseInt(e.target.value);
    }

    setValue({
      ...values,
      [e.target.name]: value,
    });
  }

  async function handleSubmit(submitedValues) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: submitedValues.name,
        email: submitedValues.email,
        message: submitedValues.message,
        subject: submitedValues.subject,
      }),
    };
    fetch("https://www.jamesanderegg.com/jeffrichards", requestOptions).then(
      (response) => {
        if (response.status === 200) {
          // form successful
          // clear errors
          setErrors([]);
          // clear form
          setValue({
            name: "",
            email: "",
            subject: "",
            mapleSyrup: "",
            message: "",
          });
          // set form sent to true and display Success
          setFormSent(true);
        } else {
          // clear form
          setValue({
            name: "",
            email: "",
            subject: "",
            mapleSyrup: "",
            message: "",
          });
        }
      }
    );
  }

  const checkForm = (e) => {
    e.preventDefault();
    let errors = [];
    // check if maple syrup is empty
    // check if other fields are empty

    Object.keys(values).map((value) => {
      // catch the honey pot and check if it is empty. if not empty === bad
      if (value === "mapleSyrup") {
        if (values[value] !== "") {
          // Do Not submit form
          errors.push(value);
        }
      } else {
        // check for empty values
        if (values[value] === "") {
          errors.push(value);
        }
      }
    });
    if (errors.length !== 0) {
      // if errors do not submit form
      setErrors(errors);
    } else {
      setErrors(errors);
      handleSubmit(values);
    }
  };

  return (
    <>
      <SEO title="Contact Page" />
      <Wrapper>
        <TitleCard>Contact Jeff</TitleCard>

       
        <TopImage
        {...data.contactPage.topPainting[0].image}
              alt="Bottom Image"
            />
        <NavStyle>
          <Nav />
        </NavStyle>
        <FormCard>
          <FormTitle>Send Jeff an email</FormTitle>
          <FormGrid>
            <form
              onSubmit={(e) => checkForm(e)}
              style={{
                alignContent: "center",
                textAlign: "center",
                margin: "auto",
              }}
            >
              <input
                type="text"
                name="name"
                id="name"
                value={values.name}
                placeholder="Enter your name"
                style={{ width: "95%" }}
                onChange={updateValue}
              />
              <br />
              <br />
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Enter your email"
                value={values.email}
                style={{ width: "95%" }}
                onChange={updateValue}
              />
              <br />
              <br />
              <input
                type="text"
                name="subject"
                id="subject"
                placeholder="Enter the subject"
                value={values.subject}
                style={{ width: "95%" }}
                onChange={updateValue}
              />
              <br />
              <input
                type="mapleSyrup"
                name="mapleSyrup"
                id="mapleSyrup"
                value={values.mapleSyrup}
                onChange={updateValue}
                className="mapleSyrup"
                style={{ display: "none" }}
              />
              <br />
              <textarea
                name="message"
                id="message"
                placeholder="Type your Message here."
                rows="8"
                value={values.message}
                style={{ width: "95%" }}
                onChange={updateValue}
              />
              <br />
              <FormButton className="submit-button" type="submit">
                Send
              </FormButton>
            </form>
          </FormGrid>
          {formSent ? (
            <FormTitle>Thank you, your message has been sent!</FormTitle>
          ) : null}
          {errors.length !== 0
            ? errors.map((error, i) => (
                <FormTitle key={i}>Please fill in the {error} field.</FormTitle>
              ))
            : null}
        </FormCard>
        <BottomImage>
          
          <Image
              {...data.contactPage.bottomPainting[0].image}
              alt="Bottom Image"
            />
        </BottomImage>
      </Wrapper>
    </>
  );
}

export const query = graphql`
  query ContactPageQuery {
    contactPage: sanityContactPage {
        name
        id
        email
        address
        phoneNumber
        topPainting {
          image {
            ...ImageWithPreview
          }
        }
        bottomPainting {
          image {
            ...ImageWithPreview
          }
        }
    }
  }
`;